<template>
  <div class="formation-portal">
    <div class="formation-portal__wrapper"
         :style="style">
      <Column>
        <p class="formation-portal__title">{{title}}</p>
        <p class="formation-portal__description">{{description}}</p>
        <div class="formation-portal__buttons">
          <slot/>
        </div>
      </Column>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormationPortal',
  props: {
    title: {
      type: String,
    },
    description: {
      type: String,
    },
    backgroundImage: {
      type: String,
    },
    backgroundPosition: {
      type: String,
    },
  },
  computed: {
    style() {
      return {
        backgroundImage: this.backgroundImage,
        backgroundPosition: this.backgroundPosition,
      };
    },
  },
};
</script>
